// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function actionTesting() {
  window.testing = true;
  ajax({
    object: 'actionHistory',
    callback: function (xhr) {
      if (xhr.data.actionHistory)
        $.each(xhr.data.actionHistory, function (id, action) {
          if (action.xhr) ajaxResponse({}, xhr);
          else if (action.type == 'click' && action.ident)
            $('#' + action.ident).trigger(click);
        });
      window.testing = false;
    },
  });
}
